import React, { Component, ChangeEvent } from 'react';

import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { NavPages } from 'global.types';
import _get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import { Box } from 'rebass';

import { Channel } from 'generated-types.d';

import { CurrencyService } from 'lib';

import { Mode } from 'stores/add-on-edit-store/add-on-edit-store.types';

import AddOnsService from 'features/add-ons/services';
import { ConfirmationModalData } from 'features/modal-dialogue/components/modals/confirmation-modal/confirmation-modal.types';
import * as InnerFormStyles from 'features/products/components/create-edit-product-layout/create-layout-inner-content/create-layout-inner-content.styles';
import * as FormStyles from 'features/products/components/create-edit-product-layout/create-layout.styles';

import ChannelCheckboxList from 'components/channel-checkbox-list';
import CheckboxToggle from 'components/checkbox-toggle';
import FormFieldWrapper from 'components/create-labeled-component';
import DropdownNative from 'components/dropdown-native/dropdown-native';
import FieldItemSelector from 'components/field-item-selector';
import FieldText from 'components/field-text';
import MediaUploader from 'components/media-uploader';
import RadioList from 'components/radio-list';

import FooterContent from './create-layout-footer';
import * as Styles from './create-layout.styles';
import * as Types from './create-layout.types';

class CreateLayout extends Component<Types.CreateLayoutProps> {
  selectedProductsToggleConfig: Types.ProductToggleItem[] = [
    {
      title: 'All products',
      slug: 'all_products'
    },
    {
      title: 'Selected products',
      slug: 'selected_products'
    }
  ];

  private addOnProductSelectionType = (): Types.ProductToggleSlug => {
    return this.props.addOnEditStore!.allProducts
      ? 'all_products'
      : 'selected_products';
  };

  private onToggleSelectedProducts = (selected: any): void => {
    this.props.addOnEditStore!.setSelectedProducts(selected);
  };

  private onAvailabilityChange = (value: boolean): void => {
    this.props.addOnEditStore!.setActive(value);
  };

  private renderPriceCurrency = (): string => {
    return !!this.props.addOnEditStore!.currency ? CurrencyService.renderCurrencySymbol(this.props.addOnEditStore!.currency) : '';
  };

  private confirmBeforeSave = (): void => {
    if (!this.props.addOnEditStore!.active) {
      const modalData: ConfirmationModalData = {
        title: 'Confirmation',
        copy: 'Do you want to put your add-on up for sale?',
        confirmButtonCopy: 'Yes please',
        cancelButtonCopy: 'No, not yet',
        errorCopy: '',
        onCancel: async (): Promise<any> => new Promise(async (resolve, reject): Promise<any> => {
          try {
            await this.saveAddon();
            resolve('');
          } catch (error) {
            reject();
          }
        }),
        confirmButtonAction: async (): Promise<any> => new Promise(async (resolve, reject): Promise<any> => {
          try {
            this.props.addOnEditStore!.setActive(true);
            await this.saveAddon();
            resolve('');
          } catch (error) {
            reject();
          }
        })
      };

      this.props.modalStore!.triggerModal({
        modalType: 'confirmation',
        data: modalData
      });
    } else {
      AddOnsService.AddOnsCreateEditService.saveAddOn();
    }
  };

  private createSaveAddOn = (): void => {
    if (this.props.addOnEditStore!.currentMode === Mode.create) {
      this.confirmBeforeSave();
    } else {
      AddOnsService.AddOnsCreateEditService.saveAddOn();
    }
  };

  private saveAddon = async (): Promise<void> => {
    return AddOnsService.AddOnsCreateEditService.saveAddOn();
  };

  private renderActiveCheckboxToggle = (): JSX.Element => (
    <InnerFormStyles.ActiveCheckbox>
      <CheckboxToggle
        isChecked={this.props.addOnEditStore!.active}
        controlValue={true}
        onChange={this.onAvailabilityChange}
      />
      <p>Active</p>
    </InnerFormStyles.ActiveCheckbox>
  );

  render(): React.ReactNode {
    const merchant = this.props.addOnEditStore?.currentMode === Mode.create ? this.props.merchantStore!.merchant! : this.props.addOnEditStore!.oldAddOn?.merchant;

    return (
      <FormStyles.Content>
        <InnerFormStyles.InnerContent>
          <InnerFormStyles.HeaderWrapper>
            <InnerFormStyles.TitleText>
              {
                this.props.addOnEditStore!.title ? this.props.addOnEditStore!.title : 'Title'
              }
            </InnerFormStyles.TitleText>
            {this.renderActiveCheckboxToggle()}
          </InnerFormStyles.HeaderWrapper>
          <InnerFormStyles.SubTitle>
            {
              this.props.addOnEditStore!.brand
                ? this.props.addOnEditStore!.brand
                : ''
            }
          </InnerFormStyles.SubTitle>

          <Styles.FormRow>
            <Box css={css`flex-grow: 1;`}>
              <FormFieldWrapper title="Title">
                <FieldText
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => this.props.addOnEditStore!.updateTitle(e.target.value)}
                  value={this.props.addOnEditStore!.title}
                  isSpellCheckEnabled={false}
                  placeholder="name"
                  maxLength={23}
                  size="normal"
                  error={!this.props.addOnEditStore!.title}
                  errorMessage="Title must be provided."
                  displayMaxLength={true}
                />
              </FormFieldWrapper>
            </Box>
          </Styles.FormRow>

          <Styles.FormRow>
            <Box css={css`width: 48%;`}>
              <Styles.FormRow>
                <Box css={css`flex-grow: 1;`}>
                  <FormFieldWrapper title="Category">
                    <DropdownNative
                      placeholder="Choose one..."
                      optionTitleField="title"
                      optionValueField="id"
                      id="add-on-categories-dropdown"
                      selected={this.props.addOnEditStore!.categoryId}
                      options={this.props.addOnEditStore!.categories}
                      onChange={this.props.addOnEditStore!.updateCategoryId}
                      fullWidth={true}
                    />
                    {(!!this.props.addOnEditStore!.oldAddOn?.merchant || !!this.props.merchantStore!.merchant) && (
                      <ChannelCheckboxList
                        selectedItems={Array.from(this.props.addOnEditStore!.selectedChannels)}
                        itemValueField="slug"
                        optionTitleField="title"
                        metadataField="count"
                        merchant={merchant}
                        onChange={(value): void => {
                          this.props.addOnEditStore!.toggleChannel(value as Channel);
                        }}
                        checkboxStyles={css`
                        margin-top: 18px;
                      `}
                        orientation="horizontal"
                      />
                    )}
                  </FormFieldWrapper>
                </Box>
              </Styles.FormRow>

              <Styles.FormRow>
                <Box css={css`flex-grow: 1;`}>
                  <FormFieldWrapper
                    title="Brand"
                    optional={true}
                  >
                    <FieldText
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => this.props.addOnEditStore!.updateBrand(e.target.value)}
                      value={this.props.addOnEditStore!.brand}
                      isSpellCheckEnabled={false}
                      placeholder="Brand"
                      maxLength={23}
                      size="normal"
                      displayMaxLength={true}
                    />
                  </FormFieldWrapper>
                </Box>
              </Styles.FormRow>
            </Box>
            <Box css={css`width: 48%;`}>
              <FormFieldWrapper
                title="Image"
                subtitle="Upload Jpeg files with a maximum filesize of 2mb"
              >
                <MediaUploader
                  onUpload={this.props.addOnEditStore!.updateMediaItem}
                  id={this.props.addOnEditStore!.mediaItem.id!}
                  imageName={this.props.addOnEditStore!.mediaItem.name}
                  index={0}
                  src={this.props.addOnEditStore!.mediaItem.src}
                  category={this.props.addOnEditStore!.mediaItem.category}
                  validationUploadError={false}
                  validationErrorMessage="Image must be provided"
                />
              </FormFieldWrapper>
            </Box>
          </Styles.FormRow>

          <Styles.FormRow>
            <Box css={css`width: 48%;`}>
              <FormFieldWrapper
                title="Price"
              >
                <FieldText
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => this.props.addOnEditStore!.updatePrice(parseFloat(e.target.value))}
                  value={this.props.addOnEditStore!.price}
                  isSpellCheckEnabled={false}
                  placeholder="amount"
                  prefix={this.renderPriceCurrency()}
                  type="number"
                  size="normal"
                  min={0}
                  error={typeof this.props.addOnEditStore!.price !== 'number' || isNaN(this.props.addOnEditStore!.price) || this.props.addOnEditStore!.price < 0}
                  errorMessage="Price must be positive"
                />
              </FormFieldWrapper>
            </Box>
            <Box css={css`width: 48%;`}>
              <FormFieldWrapper
                title="Available stock"
              >
                <FieldText
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => this.props.addOnEditStore!.updateStock(parseFloat(e.target.value))}
                  value={this.props.addOnEditStore!.stock}
                  isSpellCheckEnabled={false}
                  placeholder="round number"
                  type="number"
                  size="normal"
                  min={0}
                  error={typeof this.props.addOnEditStore!.stock !== 'number' || isNaN(this.props.addOnEditStore!.stock) || this.props.addOnEditStore!.stock < 0}
                  errorMessage="Stock must be provided"
                />
              </FormFieldWrapper>
            </Box>
          </Styles.FormRow>

          <Styles.FormRow>
            <Box css={css`width: 100%;`}>
              <FormFieldWrapper
                title="Products"
                subtitle="Attach this add-on to:"
              >
                <RadioList
                  items={this.selectedProductsToggleConfig}
                  selectedItem={this.addOnProductSelectionType()}
                  itemValueField="slug"
                  itemTitleField="title"
                  orientation="horizontal"
                  onChange={this.onToggleSelectedProducts}
                />
                { this.addOnProductSelectionType() === 'selected_products' && (
                  <Box m="20px 0">
                    <FieldItemSelector
                      items={this.props.addOnEditStore!.productsList}
                      hasSearch={true}
                      isMultiSelect={true}
                      onItemRemove={this.props.addOnEditStore!.deselectProduct}
                      selectedItemIds={this.props.addOnEditStore!.selectedProductIds}
                      setSelectedItemIds={this.props.addOnEditStore!.setSelectedProductIds}
                      modalHeadingCopy="Select some products"
                      addButtonCopy="Add product(s)"
                      noResultsHeading="We couldn't find any products"
                      noResultsCopy={(
                        <>
                          Try heading to your
                          {' '}
                          <Link
                            css={{ fontWeight: 'bold', textDecoration: 'underline' }}
                            to={NavPages.ProductsList}
                          >
                            product list
                          </Link>
                          {' '}
                          to make sure you have some custom website products created!
                        </>
                      )}
                    />
                  </Box>
                )}
              </FormFieldWrapper>
            </Box>
          </Styles.FormRow>
        </InnerFormStyles.InnerContent>
        <FooterContent
          saveAction={this.createSaveAddOn}
          isDirty={AddOnsService.AddOnsCreateEditService.isStateDirty}
        />
      </FormStyles.Content>
    );
  }
}

export default inject((stores: FxStores): InjectedFxStores => ({
  addOnEditStore: stores.addOnEditStore,
  merchantStore: stores.merchantStore,
  modalStore: stores.modalStore
}))(observer(CreateLayout));
